.billboard  {
   display: flex;
   flex-direction: column;
   margin: 1em;

}
.bio-container {
    margin-top: 5vh;
    max-width: 100%;
    padding: .5em
}
button {
    padding: .5em;
    background-color: #D0E37F;
    margin-left: 1ch;
    margin-top: 1ch;
    border-radius: 5px;
    border: 4px solid #363537;
    border: 0 solid transparent;
    font-size: 1em;
    cursor: pointer;
    transition: all .2s ease;

}
button:hover {
    border: 2px solid #363537;
    box-shadow: 3px 6px #363537;
}
button:active{
    box-shadow: 4px 8px #363537;
}
.caption {
   font-weight: 800;
}
.disabled {
    pointer-events: none;
}
dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70vw;
    transform: translate(-50%, -50%);
    border: 1px solid white;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, .8);
}
.dialog-button {
    margin-bottom: 10px;
    cursor: pointer;
    color: #363537;
    font-size: 1em;
    background-color: white;
    font-family: monospace
}
.flex-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.full-size-img {
    max-width: 100%;

}
.italics {
    font-style: italic
}
.metadata {
    font-size: .7em;
    line-height: .5;
    border-left: 2px solid black;
    padding: .5em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0;
}
.metadata-column {
    margin: 0 10px;
}
p {
    font-size: 1.1em
}
.photo {
    max-width: 100%;
}
.photo-container {
    margin-top: 1vh;
    padding: 1vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
   
}
.page-description {
    padding: 1vw;
    max-width: 70rem;
}
.photo-item {
    padding: 5px;
    margin: 1em;
}
.clear-tag{
    margin-left: 1ch;
    margin-top: 1ch;
    padding: .5em;
    font-family:monospace;
    font-size: .8em;
    cursor: pointer;
}
.tag {
  
    padding: .5em;
    background-color: #D0E37F;
    margin-left: 1ch;
    margin-top: 1ch;
    border-radius: 5px;
    border: 4px solid #363537;
    border: 0 solid transparent;
    font-family:monospace;
    font-size: .8em;
    cursor: pointer;
    transition: border 0.2s ease;

}
.tag:hover{
    border: 2px solid #363537;
    box-shadow: 2px 4px #363537;
}

.no-highlight:hover {
    border: 0px solid black;
    box-shadow: 0px 0px black;
    cursor: auto;
   
}
.tags-container {
    margin-top: 10vh;
    margin-right: 5vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
}
h1 {
    font-size: 2.5em;
    font-family: 'Atkinson Hyperlegible', sans-serif;
}
.hero-image {
    max-height: 40vh;
    margin-top: 10vh;
}
.wrapper {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
}
.document {
    height: 64px !important;
    width: 64px !important;
}

.highlight {
    background-color: #D0E37F;

}
.text-center {
    text-align: center;
}
.thumbnail {
    max-width: 100%;
    margin-bottom: 0;
}