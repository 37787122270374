.cover-image {
  width: 100%;
  height: 500px;
  margin: 2em;
  border: 5px solid white;
}

.cta-bar {
  position: absolute;
  left: 25%;
  top: auto;
  bottom: 1em;
}
.details {
  margin-bottom: 5vh;
  margin-top: 3vh;
}
.description {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.example-title {
  font-weight: 400;
}
.featured-animation {
  height: 400px;
  padding: 1em;
}

.featured-image {
  max-width: 100%;
  padding: 1em;
}
.larger {
  font-size: 2.5em
}
li {
  list-style-type: disc !important;

}
.smaller-image {
  padding: 1em;
}
.highlight-box {
  padding: 1em;
}
.highlight-text {
  text-align: center;
}
.icon-button {
  padding: 1em;
  font-size: 2em;
  font-weight: 800;
  cursor: pointer;
  animation: smallbounce 0.8s infinite alternate;
}

.modal {
  background-color: white;
  border-radius: 5px;
  padding: 1em;
  border: 2px solid black;

}
.number {
  border-radius: 5%;
  padding: 1em;
  font-size: 2em;
  margin: 0.5em;
  border-bottom: 3px solid black;
}
.password {font-size: 1.5em}
.prominent {
  font-size: 1.5em;
  
}
.title-only {
  font-size: 1.4em;
  text-align: left;
}
.tag-image {
  height: 50px;
  margin: 5px;
}
.text-container {
  position: relative;
  overflow: hidden;
  height: 100%;
  margin-bottom: 2px;
  padding: 2em 1.9em 1em 1.8em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-radius: 4px;
  font-size: 1.2em;
  border: 2px solid #5BC0BE;
}
.space {
  margin: 1em
}
.text-right{
  text-align: right;
}
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 35px;
  height: 0;
  overflow: hidden;
  margin: 1em;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


@keyframes smallbounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-1em);
  }
}
