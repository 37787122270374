.contact-button > h3 {
   font-size: 2em; 
}
.contact-button {
   margin-left: 5ch
}
.contact-icon {
   height: 50px;
   width: 50px;
}
.padding-sm {
   padding: .5em;
   margin: .5em;
}